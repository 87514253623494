import React from "react";
import { PatientPage, SEO } from "components";
import { Row, Col } from 'react-flexbox-grid';
import { DiscussionGuideCallout, PatientSavingsCallout } from "components/shared/callouts";
import imgMan from 'images/about-hypogonadism-man.png';

import './what-is-hypogonadism.scss';

const pageDescription = "Ask your doctor about AVEED® (testosterone undecanoate) injection Clll. See Safety Information, including Boxed Warning, benefits and risks, and full Prescribing Information.";

const AboutPage = () => {
  return (
    <PatientPage pageClassName="about-hypogonadism">
      <SEO pageTitle="Ask About Treatment" pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <Row className="hero">
          <Col xs={12} md={7}>
            <h1>What Is Hypogonadism?</h1>
            <p className=''>Hypogonadism is a term used to describe low testosterone in men, and occurs when the male testes are unable to produce sufficient testosterone (the male sex hormone). This results in low levels of testosterone.</p>
          </Col>
          <Col xs={12} md={5} className="img-block">
            <img src={imgMan} className="img-man-hypogonadism" alt="what is hypogonadism?" />
          </Col>
        </Row>

        <Row>
          <Col xs={12} >
            <div className="treating-hypogonadism-block">
              <h2>Ask About Treating Hypogonadism with AVEED<sup>&reg;</sup></h2>
              <p>If you've been diagnosed with hypogonadism, you may be talking about treatment with your doctor.</p>
              <p>AVEED<sup>&reg;</sup> (testosterone undecanoate) injection is a long-acting prescription medicine that contains testosterone and is used to treat adult males who have hypogonadism.</p>
            </div>
          </Col>
        </Row>
        <Row className="callout-blocks-container">
          <Col xs={12} md={6}>
            <DiscussionGuideCallout />
          </Col>
          <Col xs={12} md={6}>
            <PatientSavingsCallout />
          </Col>
        </Row>
      </div>

    </PatientPage>
  )
}

export default AboutPage